import React, { useRef, useEffect, useState } from "react";
import LineStep from "../images/sedstart/Line-step.svg";

const steps = [
    {
        id: 1,
        title: "Step 1",
        description: "Create plain english steps and reuse them across any tests",
    },
    {
        id: 2,
        title: "Step 2",
        description: "Automate Steps using page object building blocks",
    },
    {
        id: 3,
        title: "Step 3",
        description: "Create static and dynamic suites to run multiple tests",
    },
    {
        id: 4,
        title: "Step 4",
        description: "Run across multiple environments using data profiles",
    },
];

const HorizontalScrollSteps = () => {
    const containerRef = useRef(null);
    const step4Ref = useRef(null); // Reference for the 4th step
    const sectionRef = useRef(null); // Reference to detect when the section is in view
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        if (containerRef.current) {
            setScrollPosition(containerRef.current.scrollLeft);
        }
    };

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    // Auto-scroll to the 4th step when the section comes into view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                // Check if the viewport is wider than a mobile threshold (e.g., 768px)
                const isLargeScreen = window.matchMedia("(min-width: 768px)").matches;
                if (entry.isIntersecting && isLargeScreen) {
                    // Scroll to the 4th step when section comes into view
                    step4Ref.current?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                    });
                }
            },
            {
                root: null, // Observe the viewport
                threshold: 1.0, // Trigger when 50% of the section is visible
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div className="sedstart-steps" ref={sectionRef}>
            <div className="sedstart-steps-grid" ref={containerRef}>
                {steps?.map((step, id) => (
                    <div
                        className="sedstart-steps-grid__item"
                        key={id}
                        ref={id === 3 ? step4Ref : null} // Attach ref to the 4th step
                    >
                        <button className="step-title gap-05x">{step.title}</button>
                        <span className="step-desc">{step.description}</span>
                    </div>
                ))}

                <div className="progress-line">
                    {/* <img src={LineStep} alt="line-step" className="line-step" /> */}
                    <div className="line-step"> </div>
                    <div className="circle circle-one"></div>
                    <div className="circle circle-two"></div>
                    <div className="circle circle-three"></div>
                    <div className="circle circle-four"></div>
                </div>
            </div>
        </div>
    );
};

export default HorizontalScrollSteps;
