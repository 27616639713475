import React, { useState } from "react";
import UpArrow from "../images/uparrow.svg";
import DownArrow from "../images/downarrow.svg";
import MessageFaq from "../images/sedstart/message-faq.svg";
const SedStartFaq = () => {
    const [selected, setSelected] = useState(null);
    const toggle = (id) => {
        if (selected === id) {
            return setSelected(null);
        }
        setSelected(id);
    };
    const FaqList = [
        {
            id: 1,
            mesgFaq: MessageFaq,
            title: "Do We need to code anything for automating tests?",
            description: "No, You don’t need to write any code to automate tests.",
        },
        {
            id: 2,
            mesgFaq: MessageFaq,

            title: "Do I need to Install anything to use the platform",
            description:
                "The whole platform is managed you don’t need to Install anything if you run your tests in our Infrastructure.",
        },
        {
            id: 3,
            mesgFaq: MessageFaq,

            title: "What if I want to run tests locally?",
            description:
                "We provide command line interface tool which can be used to run same tests locally or in any CI/CD platform.",
        },
        {
            id: 4,
            mesgFaq: MessageFaq,

            title: "Do you support mobile automation?",
            description:
                "Mobile automation is not yet supported but it's under development and we will support mobile automation soon.",
        },
        {
            id: 5,
            mesgFaq: MessageFaq,

            title: "What if I want to move away from this platform later on.",
            description:
                "In case you don’t wish to continue the platform we will convert your automation structure to a playwright project which you can continue to use and maintain on your own.",
        },
    ];
    return (
        <>
            <div className="sedstart-faq-wrapper">
                {/* <div className="sedin-faq__grid"> */}
                <div className="sedin-faq__grid-item">
                    {FaqList?.map((item, id) => (
                        <div key={id} className="accordian  gap-05x" onClick={(e) => toggle(id)}>
                            <div className="accordian-header flex flex-jc-sb flex-ai-c">
                                <h4 className="sedin-faq__grid__title  text-p2 text-fw-medium text-primary-clr flex flex-ai-c">
                                    <img src={item.mesgFaq} alt="faq" className="msg-img" />
                                    {item.title}
                                </h4>
                                {selected === id ? (
                                    <img src={UpArrow} alt="upArrow" />
                                ) : (
                                    <img src={DownArrow} alt="downArrow" />
                                )}
                            </div>
                            <div
                                className={
                                    selected === id
                                        ? "sedin-faq__grid__description text-clr-secondary show"
                                        : "sedin-faq__grid__description text-clr-secondary"
                                }
                            >
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
                {/* </div> */}
            </div>
        </>
    );
};

export default SedStartFaq;
