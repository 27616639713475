import React from "react";
import WhySedSolutionOne from "../images/sedstart/whysed-01.svg";
import WhySedSolutionTwo from "../images/sedstart/whysed-02.svg";
import WhySedSolutionThree from "../images/sedstart/whysed-03.svg";
import WhySedSolutionFour from "../images/sedstart/whysed-04.svg";
import WhySedSolutionFive from "../images/sedstart/whysed-05.svg";
import WhySedSolutionSix from "../images/sedstart/whysed-06.svg";

const WhySedStart = () => {
    const whySolutions = [
        {
            id: 1,
            whySedImage: WhySedSolutionOne,
            title: "Eliminate Manual Errors",
            description: "Say goodbye to the bugs/errors from traditional testing process",
        },
        {
            id: 2,
            whySedImage: WhySedSolutionTwo,
            title: "Speed To Market",
            description: "Accelerate your progress and speed to the market in a swift time.",
        },
        {
            id: 3,
            whySedImage: WhySedSolutionThree,
            title: "Maximize productivity",
            description: "0% errors and fast final products will maximize your productivity.",
        },
        {
            id: 4,
            whySedImage: WhySedSolutionFour,
            title: "Save Your Precious Time",
            description: "Break down hours into minutes & minutes into seconds.",
        },
        {
            id: 5,
            whySedImage: WhySedSolutionFive,
            title: "Effortless Onboarding",
            description:
                "With very less setup and deployment, users can easily get started with automation testing right from “Day 1”",
        },
        {
            id: 5,
            whySedImage: WhySedSolutionSix,
            title: "Universal Compatibility",
            description:
                "Robust user interface/scriptless automation easily suits all level professionals.",
        },
    ];
    return (
        <div className="why-sedstart-grid">
            {whySolutions?.map((solution, id) => (
                <div className="why-sedstart-grid__item" key={id}>
                    <div className="why-sedstart-grid__item-single">
                        <img
                            src={solution.whySedImage}
                            alt="solution-img"
                            className="why-sedstart-img"
                            loading="lazy"
                        />
                        <p className="text-p1 text-clr-white text-fw-strong solution-title">
                            {solution.title}
                        </p>
                        <div className="text-p4 text-fw-normal solution-desc">
                            {solution.description}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default WhySedStart;
